import axiosIns from "@/libs/axios";

export async function postQuestionnaire(reqData) {
  console.log("Posting qualifications with ", reqData);
  return await axiosIns.post("/compassion/admin/operatorSaveQuestionnaire", reqData, {});
}
export async function postContact(reqData) {
  console.log("Posting contact with ", reqData);
  return await axiosIns.post("/compassion/admin/operatorSaveContact", reqData, {});
}
export async function postAddress(reqData) {
  console.log("Posting address with ", reqData);
  return await axiosIns.post("/compassion/admin/operatorSaveAddress", reqData, {});
}
export async function postReleases(reqData) {
  console.log("Posting releases with ", reqData);
  return await axiosIns.post("/compassion/admin/operatorSaveReleases", reqData, {});
}
export async function uploadDocument(reqData) {
  console.log("Uploading document ", reqData.documentType);

  return await axiosIns.post(`/compassion/admin/operatorUploadDocument/`, reqData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
export async function requestValidateEmailPhone(reqData) {
  return await axiosIns.post("/compassion/admin/validatePatientEmailPhone", reqData, {});
}
export async function requestCreatePatient(reqData) {
  return await axiosIns.post("/compassion/admin/operatorAddPatient", reqData, {});
}

export async function lookupPatient(reqData) {
  return await axiosIns.post("/compassion/admin/operatorLookupPatient", reqData, {});
}
